<template>
  <footer
    class="layout-footer"
    ref="footer"
    :style="{ backgroundColor: helperStore.styleData.backgroundColor }"
  >
    <nav role="navigation" class="layout-nav" :style="{ fontFamily: helperStore.headerFont }">
      <a
        @click.prevent="handleTabClick(RouteNamesEnum.HOME)"
        :class="{ active: route.name === RouteNamesEnum.HOME }"
        href="#"
        role="link"
        class="layout-nav__item"
        :aria-label="'Go to ' + helperStore.i18n('home_tab_name')"
      >
        <i class="icon-House"></i>
        <span>{{ helperStore.i18n('home_tab_name') }}</span>
      </a>

      <a
        @click.prevent="handleTabClick(RouteNamesEnum.INFO)"
        :class="{ active: route.name === RouteNamesEnum.INFO }"
        href="#"
        role="link"
        class="layout-nav__item"
        id="footer-info-link"
        :aria-label="'Go to ' + helperStore.i18n('info_tab_name')"
      >
        <i class="icon-Information"></i>
        <span>{{ helperStore.i18n('info_tab_name') }}</span>
      </a>

      <a
        v-if="!isStandardUser"
        @click.prevent="handleTabClick(RouteNamesEnum.GUIDE)"
        :class="{ active: route.name === RouteNamesEnum.GUIDE }"
        href="#"
        class="layout-nav__item"
        role="link"
        :aria-label="'Go to ' + helperStore.i18n('guide_tab_name')"
      >
        <i class="icon-Star"></i>
        <span>{{ helperStore.i18n('guide_tab_name') }}</span>
      </a>

      <a
        v-if="showMapTab"
        href="#"
        @click.prevent="handleTabClick(RouteNamesEnum.MAP)"
        class="layout-nav__item"
        :class="{ active: route.name === RouteNamesEnum.MAP }"
        role="link"
        :aria-label="'Go to ' + helperStore.i18n('map_tab_name')"
      >
        <i class="icon-Pin-1"></i>
        <span>{{ helperStore.i18n('map_tab_name') }}</span>
      </a>

      <a
        href="#"
        @click.prevent="handleTabClick(RouteNamesEnum.SEARCH)"
        class="layout-nav__item"
        :class="{ active: route.name === RouteNamesEnum.SEARCH }"
        role="link"
        :aria-label="'Go to ' + helperStore.i18n('search_tab_name')"
      >
        <i class="icon-Loupe"></i>
        <span>{{ helperStore.i18n('search_tab_name') }}</span>
      </a>
    </nav>
  </footer>
</template>

<script lang="ts" setup>
import RouteNamesEnum from '@/router/route-names'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { isMobile, sendPlausibleEvent } from '@/utils/helpers'
import { useHelperStore } from '@/pinia/helper.store'
import { useMainStore } from '@/pinia/main.store'
import { PlausibleEvents } from '@/utils/consts'

const helperStore = useHelperStore()
const mainStore = useMainStore()
const route = useRoute()
const router = useRouter()

const showMapTab = computed(() => !mainStore.propertyInfo.hide_map_tab)
const isStandardUser = computed(() => mainStore.propertyInfo.owner_type === 'standard')

function handleTabClick(
  routeName:
    | RouteNamesEnum.SEARCH
    | RouteNamesEnum.MAP
    | RouteNamesEnum.HOME
    | RouteNamesEnum.INFO
    | RouteNamesEnum.GUIDE
) {
  // Ignore redundant navigation on desktop, preserve on mobile
  if (route.name === routeName && !isMobile()) {
    return
  }

  switch (routeName) {
    case RouteNamesEnum.MAP:
      sendPlausibleEvent(PlausibleEvents.MAP_TAB_CLICK)
      break
    case RouteNamesEnum.SEARCH:
      sendPlausibleEvent(PlausibleEvents.SEARCH_TAB_CLICK)
      break
  }

  router.push({ name: routeName })
}
</script>
