import { acceptHMRUpdate, defineStore } from 'pinia'
import { PropertyInfo } from '@/interfaces/state.interface'
import { ref } from 'vue'
import { Category, Subcategory, Topic } from '@/interfaces/guide.interface'
import api from '@/api'
import RouteNamesEnum from '@/router/route-names'
import { useHelperStore } from '@/pinia/helper.store'
import { useRoute } from 'vue-router'
import {
  getCurrentLanguageBasedOnBrowser,
  preloadImages,
  sendPlausibleEvent
} from '@/utils/helpers'
import cloneDeep from 'lodash/cloneDeep'
import { PlausibleEvents, PlausibleProperties } from '@/utils/consts'

export const useMainStore = defineStore('main', () => {
  const helperStore = useHelperStore()
  const route = useRoute()

  const propertyInfo = ref<PropertyInfo>({} as PropertyInfo)
  const infoCategories = ref<Category[]>([])
  const guideCategories = ref<Category[]>([])
  const activeSubcategory = ref<Subcategory>(null)
  const isBannerActive = ref(false)

  // may not work, deep prop set. CHECK
  async function fetchGooglePlace(topic: Topic) {
    const googlePlace = await api.getGooglePlace(
      topic.original_id || topic.id,
      helperStore.currentLanguage
    )

    const { id: subcategoryId, category_id: categoryId } = activeSubcategory.value
    const categories =
      route.name === RouteNamesEnum.INFO ? infoCategories.value : guideCategories.value
    const category = categories.find((c) => c.id === categoryId)
    const subcategory = category.subcategories.find((s) => s.id === subcategoryId)
    const gpTopic = subcategory.topics.find((t) => t.id === topic.id)

    gpTopic.google_place = { ...topic.google_place, ...googlePlace }

    activeSubcategory.value = { ...subcategory }
  }

  async function fetchMainData(id = helperStore.guideToken) {
    const { content, translations, map, style, home, is_banner_active } = await api.getGuide(id)

    // Preload all images for caching
    const photosToPreload = [home.desktop_photo, home.mobile_photo, ...content.all_photos]

    if (home.avatar) {
      photosToPreload.unshift(home.avatar.url)
    }

    preloadImages(photosToPreload)

    isBannerActive.value = is_banner_active
    helperStore.mapData = map
    infoCategories.value = cloneDeep(content.info_content)
    guideCategories.value = cloneDeep(content.master_content)
    propertyInfo.value = home
    helperStore.availableLanguages = translations
    helperStore.currentLanguage = getCurrentLanguageBasedOnBrowser(translations)

    await helperStore.setStyleData(style)
  }

  function updateActiveSubcategory(subcategory: Subcategory | null) {
    // not a reset
    if (subcategory) {
      // subcategory has changed
      if (subcategory.id !== activeSubcategory.value?.id) {
        sendPlausibleEvent(PlausibleEvents.SUBCATEGORY_VIEW, {
          [PlausibleProperties.SUBCATEGORY_ID]: subcategory.id
        })
      }

      // category has changed
      if (subcategory.category_id !== activeSubcategory.value?.category_id) {
        sendPlausibleEvent(PlausibleEvents.CATEGORY_VIEW, {
          [PlausibleProperties.CATEGORY_ID]: subcategory.category_id
        })
      }
    }

    activeSubcategory.value = subcategory
  }

  return {
    propertyInfo,
    infoCategories,
    guideCategories,
    activeSubcategory,
    isBannerActive,
    fetchGooglePlace,
    fetchMainData,
    updateActiveSubcategory
  }
})

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot))
}
